<template>
  <div>
    <div
      class="layout-header-section d-lg-flex d-block justify-content-between"
    >
      <div class="header-label col-lg-5 col-12">Guest Reported By Host</div>
      <div class="col-lg-7 col-12 text-end d-flex justify-content-end">
        <search-by-name
          :searchNameBtnActive="searchnamebtnactive"
          :searchNameLoader="searchnameloader"
          :hideSearchName="hidesearchname"
          @search-name="searchFnameGuest"
          @search-stop="searchNameGuestStop"
        />
        <button
          type="button"
          class="header-btn-outer btn btn-primary"
          @click="addReport()"
        >
          Add Report
        </button>
      </div>
    </div>
    <!-- datatable start here -->
    <div class="company-section-outer">
      <div class="layout-content-section">
        <div
          class="custom-ultima-datatable custom-voter-datable custom-without-checkbox-datatable"
          style="height: calc(100vh - 141px)"
        >
          <DataTable
            :value="guestList"
            :scrollable="true"
            columnResizeMode="fit"
            scrollHeight="flex"
            :paginator="true"
            :rows="30"
            :totalRecords="totalRecords"
            @page="changePage($event)"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            responsiveLayout="scroll"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            :lazy="true"
            dataKey="aa1"
            selectionMode="single"
            :metaKeySelection="false"
            @rowSelect="perticularUserHistory(selectedGuest)"
            v-model:selection="selectedGuest"
            :loading="loading"
          >
            <template #empty>No records found.</template>
            <template #loading>Loading records. Please wait...</template>
            <!-- <Column
              headerStyle="width: 3%"
              bodyStyle="width: 3%"
              class="justify-content-center"
              selectionMode="multiple"
            ></Column> -->
            <Column
              header="Select All"
              headerStyle="width: 96%"
              bodyStyle="width: 96%"
            >
              <template #body="{ data }">
                <div class="company-detail-box-outer mb-3">
                  <div class="row">
                    <div class="col-lg-3 col-md-3 col-12">
                      <div class="row">
                        <div class="col-lg-12 col-md-12 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Guest Name</label>
                            <div class="from-label-value text-capitalize">
                              {{ data.aw3 ? data.aw3 : "N/A" }}
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Reported Reason</label>
                            <div class="from-label-value text-capitalize">
                              {{ data.aw7 ? data.aw7 : "N/A" }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-12">
                      <div class="row">
                        <div class="col-lg-12 col-md-12 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">User Name</label>
                            <div class="from-label-value text-capitalize">
                              {{ data.aw5 ? data.aw5 : "N/A" }}
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Reason Details</label>
                            <div class="from-label-value text-capitalize">
                              <span
                                v-if="!activate_read_key_arr.includes(data.aw8)"
                                >{{ data.aw8.slice(0, 50).replaceAll("*", "") }}
                              </span>
                              <a
                                v-if="
                                  data.aw8.length >= 50 &&
                                  !activate_read_key_arr.includes(data.aw8)
                                "
                                @click="activateReadMore(data.aw8)"
                                class="showmorebody ps-1"
                                >read more</a
                              >
                              <span
                                v-if="activate_read_key_arr.includes(data.aw8)"
                                v-html="data.aw8.replaceAll('*', '')"
                              ></span>
                              <span>
                                <a
                                  v-if="
                                    data.aw8.length >= 50 &&
                                    activate_read_key_arr.includes(data.aw8)
                                  "
                                  @click="deactivateReadMore(data.aw8)"
                                  class="showlessbody ps-1"
                                  >read less</a
                                >
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-12">
                      <div class="row">
                        <div class="d-flex justify-content-between">
                          <div class="col-lg-4 col-md-4 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">Reported By</label>
                              <div class="from-label-value text-capitalize">
                                <span v-if="data.aw10 == 1">{{ "Admin" }}</span>
                                <span v-else-if="data.aw10 == 2">{{
                                  "Host"
                                }}</span>
                                <span v-else-if="data.aw10 == 3">{{
                                  "Police"
                                }}</span>
                                <span v-else>{{ "N/A" }}</span>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-6 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">Reg. Date Time</label>
                              <div class="from-label-value">
                                {{ format_timestamp(data.aw9) }}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-2 col-md-2 col-12">
                            <button
                              type="button"
                              title="Report History"
                              class="btn custom-outline-view-btn me-2"
                              @click="perticularUserHistory(data)"
                            >
                              <i class="pi pi-history call-btn-color"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </Column>
          </DataTable>
        </div>
      </div>
    </div>
    <!-- start history modal  -->
    <div class="modal-mask" v-if="isModalOpenToViewHistory">
      <div
        class="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered custom-modal-outer"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">Report History</h5>
            <button
              type="button"
              class="btn-close"
              @click="isModalOpenToViewHistory = false"
            ></button>
          </div>
          <div class="modal-body modal-body-scroll-outer" style="height: 488px">
            <div class="company-section-outer">
              <div class="layout-content-section">
                <div
                  class="custom-ultima-datatable custom-voter-datable custom-without-checkbox-datatable"
                  style="height: calc(100vh - 141px)"
                >
                  <DataTable
                    :value="historyData"
                    :scrollable="true"
                    columnResizeMode="fit"
                    scrollHeight="flex"
                    :paginator="true"
                    :rows="30"
                    :totalRecords="totalRecords"
                    @page="changePage($event)"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    responsiveLayout="scroll"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                    :lazy="true"
                    dataKey="aa1"
                    v-model:selection="selectVisitor"
                    :loading="loading"
                    :object="true"
                  >
                    <template #empty>No records found.</template>
                    <template #loading
                      >Loading records. Please wait...</template
                    >
                    <Column
                      header="Select All"
                      headerStyle="width: 96%"
                      bodyStyle="width: 96%"
                    >
                      <template #body="{ data }">
                        <div class="company-detail-box-outer">
                          <div class="row">
                            <div class="col-lg-3 col-md-3 col-12">
                              <div class="row">
                                <div class="col-lg-12 col-md-12 col-12">
                                  <div class="customer-label-group mb-2">
                                    <label class="form-label">Guest Name</label>
                                    <div
                                      class="from-label-value text-capitalize"
                                    >
                                      {{ data.aw3 ? data.aw3 : "N/A" }}
                                    </div>
                                  </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-12">
                                  <div class="customer-label-group mb-2">
                                    <label class="form-label"
                                      >Reported Reason</label
                                    >
                                    <div
                                      class="from-label-value text-capitalize"
                                    >
                                      {{ data.aw7 ? data.aw7 : "N/A" }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-12">
                              <div class="row">
                                <div class="col-lg-12 col-md-12 col-12">
                                  <div class="customer-label-group mb-2">
                                    <label class="form-label">User Name</label>
                                    <div
                                      class="from-label-value text-capitalize"
                                    >
                                      {{ data.aw5 ? data.aw5 : "N/A" }}
                                    </div>
                                  </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-12">
                                  <div class="customer-label-group mb-2">
                                    <label class="form-label"
                                      >Reason Details</label
                                    >
                                    <div
                                      class="from-label-value text-capitalize"
                                    >
                                      <span
                                        v-if="
                                          !activate_read_key_arr.includes(
                                            data.aw8
                                          )
                                        "
                                        >{{
                                          data.aw8
                                            .slice(0, 50)
                                            .replaceAll("*", "")
                                        }}
                                      </span>
                                      <a
                                        v-if="
                                          data.aw8.length >= 50 &&
                                          !activate_read_key_arr.includes(
                                            data.aw8
                                          )
                                        "
                                        @click="activateReadMore(data.aw8)"
                                        class="showmorebody ps-1"
                                        >read more</a
                                      >
                                      <span
                                        v-if="
                                          activate_read_key_arr.includes(
                                            data.aw8
                                          )
                                        "
                                        v-html="data.aw8.replaceAll('*', '')"
                                      ></span>
                                      <span>
                                        <a
                                          v-if="
                                            data.aw8.length >= 50 &&
                                            activate_read_key_arr.includes(
                                              data.aw8
                                            )
                                          "
                                          @click="deactivateReadMore(data.aw8)"
                                          class="showlessbody ps-1"
                                          >read less</a
                                        >
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-12">
                              <div class="row">
                                <div class="d-flex justify-content-between">
                                  <div class="col-lg-4 col-md-4 col-12">
                                    <div class="customer-label-group mb-2">
                                      <label class="form-label"
                                        >Reported By</label
                                      >
                                      <div
                                        class="from-label-value text-capitalize"
                                      >
                                        <span v-if="data.aw10 == 1">{{
                                          "Admin"
                                        }}</span>
                                        <span v-else-if="data.aw10 == 2">{{
                                          "Host"
                                        }}</span>
                                        <span v-else-if="data.aw10 == 3">{{
                                          "Police"
                                        }}</span>
                                        <span v-else>{{ "N/A" }}</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-lg-6 col-md-6 col-12">
                                    <div class="customer-label-group mb-2">
                                      <label class="form-label"
                                        >Reg. Date Time</label
                                      >
                                      <div class="from-label-value">
                                        {{ format_timestamp(data.aw9) }}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                    </Column>
                  </DataTable>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end for history modal -->
  </div>
</template>
<script>
import searchByName from "../../../components/searchByName.vue";
import commonMixin from "../../../mixins/commonMixin.js";
import useValidate from "@vuelidate/core";
import ApiService from "../../../service/ApiService";
// import moment from 'moment';

export default {
  mixins: [commonMixin],
  components: {
    "search-by-name": searchByName,
  },
  data() {
    return {
      v$: useValidate(),
      activate_read_key_arr: [],
      // allVisitorsList: [],
      rowId: "",
      mobile_number: "",
      AadharTabDetails: "",
      guestVisitingHistoryTabDetails: [],
      LicenseTabDetails: "",
      PassportTabDetails: "",
      guestfiltermodalsflag: false,
      aw3: "",
      aa13: "",
      guestList: [],
      totalRecords: 0,
      page_no: 0,
      viewguestdetailstatus: false,
      errmsg: "",
      errStatus: false,
      selectedGuest: null,
      aadhardivdisplayflag: 0,
      loading: false,
      showloaderguesthistorymodal: false,
      showloaderaadharmodal: false,
      showloaderdrivingmodal: false,
      showloaderpassportmodal: false,
      client_info: "",
      hoststorageimgpath: "",
      searchmobile: "",
      searchmobloader: false,
      searchfullname: "",
      searchnameloader: false,
      searchmobbtnactive: false,
      searchnamebtnactive: false,
      hidesearchname: false,
      hidesearchmob: false,
      reasonList: [],
      countrynameList: [],
      aa30: "",
      aa49: [],
      aa12: [],
      agerange: "",
      agerangeeerr: "",
      agerangefrom: "",
      agerangeto: "",
      agerangefromeerr: "",
      agerangetoeerr: "",
      aa18: [],
      pincodevalue: "",
      aa24: [],
      aadharcardnumber: "",
      aa41: "",
      aadharerr: "",
      pincodeerr: "",
      filterareaList: [],

      isShowAadharNo: "",
      isShowMobileNo: "",
      user_info: "",
      isModalOpenToViewHistory: false,
      historyData: [],
      selectVisitor: null,
    };
  },
  ApiService: null,
  created() {
    this.ApiService = new ApiService();
  },
  validations() {
    return {};
  },

  mounted() {
    this.getVisitorReports();
  },
  methods: {
    perticularUserHistory(data) {
      this.isModalOpenToViewHistory = true;
      this.getVisitorReportsHistory(data);
      console.log("data", data);
    },
    addReport() {
      this.$router.push("/guests/addreport");
    },
    maskedNumber(getvalue) {
      const maskedDigits = "X".repeat(getvalue.length - 6);
      const FirstThreeDigits = getvalue.slice(0, 3);
      const lastThreeDigits = getvalue.slice(-3);
      return FirstThreeDigits + maskedDigits + lastThreeDigits;
    },
    getVisitorReportsHistory(e) {
      this.loading = true;
      this.ApiService.getVisitorReportsHistory({ aa1: e.aw2 }).then((data) => {
        if (data.status == 200) {
          this.historyData = data.data;
        } else {
          this.historyData = [];
        }
      });
      this.loading = false;
    },
    changePage(event) {
      this.page_no = event.page;
      this.getVisitorReports({ page_no: this.page_no });
    },
    getCountryList(e) {
      this.ApiService.getCountryList(e).then((data) => {
        if (data.status == 200) {
          this.countrynameList = data.data;
        } else {
          this.countrynameList = null;
        }
      });
    },
    // Search Voter Header Input function start here
    searchFnameGuest(searchfullname) {
      this.hidesearchname = false;
      this.searchnameloader = true;
      this.aw3 = searchfullname;
      this.getVisitorReports({
        page_no: this.page_no,
        aw3: this.aw3,
      });
      this.searchnamebtnactive = true;
    },
    searchNameGuestStop() {
      this.searchnamebtnactive = false;
      this.searchfullname = "";
      this.getVisitorReports({
        page_no: this.page_no,
      });
      this.searchnameloader = false;
      this.hidesearchname = false;
    },
    // Search Voter Header Input function end here
    // getlicensedetails() {
    //   this.showloaderdrivingmodal = true;
    //   this.ApiService.getlicensedetails({ aa1: this.rowId }).then((data) => {
    //     if (data.success == true) {
    //       this.LicenseTabDetails = data.data;
    //       this.showloaderdrivingmodal = false;
    //     } else {
    //       this.showloaderdrivingmodal = false;
    //       this.LicenseTabDetails = "";
    //     }
    //   });
    // },
    // getPassportdetails() {
    //   this.showloaderpassportmodal = true;
    //   this.ApiService.getPassportdetails({ aa1: this.rowId }).then((data) => {
    //     if (data.success == true) {
    //       this.PassportTabDetails = data.data;
    //       this.showloaderpassportmodal = false;
    //     } else {
    //       this.showloaderpassportmodal = false;
    //       this.PassportTabDetails = "";
    //     }
    //   });
    // },
    // getaadhardetails() {
    //   this.showloaderaadharmodal = true;
    //   this.ApiService.getaadhardetails({ aa1: this.rowId }).then((data) => {
    //     if (data.success == true) {
    //       this.AadharTabDetails = data.data;
    //       this.aadhardivdisplayflag = 1;
    //       this.showloaderaadharmodal = false;
    //     } else {
    //       this.aadhardivdisplayflag = 0;
    //       this.showloaderaadharmodal = false;
    //     }
    //   });
    // },
    // guestDetailModalClose() {
    //   this.viewguestdetailstatus = false;
    // },
    // guestFilterModalOpen() {
    //   this.guestfiltermodalsflag = true;
    //   this.getCountryList();
    //   this.getVisitingReason();
    // },
    // guestFilterModalClose() {
    //   this.guestfiltermodalsflag = false;
    // },
    // btnFilterSearch(
    //   aa30,
    //   aa49,
    //   aa12,
    //   agerange,
    //   agerangefrom,
    //   agerangeto,
    //   aa18,
    //   pincodevalue,
    //   aa24,
    //   aadharcardnumber,
    //   aa41
    // ) {
    //   if (
    //     !this.agerangeeerr &&
    //     !this.pincodeerr &&
    //     !this.aadharerr &&
    //     !this.agerangefromeerr &&
    //     !this.agerangetoeerr
    //   ) {
    //     this.guestfiltermodalsflag = false;
    //     this.aa30 = aa30;
    //     this.aa49 = aa49;
    //     this.aa12 = aa12;
    //     this.agerange = agerange;
    //     this.agerangefrom = agerangefrom;
    //     this.agerangeto = agerangeto;
    //     this.aa18 = aa18;
    //     this.pincodevalue = pincodevalue;
    //     this.aa24 = aa24;
    //     this.aadharcardnumber = aadharcardnumber;
    //     this.aa41 = aa41;
    //     //     this.getHostVisitorHistoryList({
    //     //         page_no: this.page_no,
    //     //         aa30: this.aa30,
    //     //         aa49: this.aa49,
    //     //         aa12: this.aa12,
    //     //         aa11: this.agerange,
    //     //         ak19from: this.agerangefrom,
    //     //         ak19to: this.agerangeto,
    //     //         aa18: this.aa18,
    //     //         aa25: this.pincodevalue,
    //     //         aa24: this.aa24,
    //     //         aa33: this.aadharcardnumber,
    //     //         aa41: this.aa41,
    //     //     });
    //   }
    // },
    // onlysingleagechange($event) {
    //   this.agerangefrom = "";
    //   this.agerangeto = "";
    //   this.agerangefromeerr = "";
    //   this.agerangetoeerr = "";
    //   let keyCode = $event.keyCode ? $event.keyCode : $event.which;
    //   if (keyCode < 48 || keyCode > 57) {
    //     $event.preventDefault();
    //   }
    // },
    // validateAadharCardNumber(e) {
    //   this.errStatus = false;
    //   if (!this.validAadharCard(e)) {
    //     this.aadharerr = "Please enter valid aadhar number";
    //     this.errStatus = true;
    //   }
    //   if (this.errStatus == false) {
    //     this.aadharerr = "";
    //     return true;
    //   }
    // },
    validAgeRange: function (e) {
      var re = /([0-9]){1,2}$/;
      return re.test(e);
    },
    validateAgeRangeFromNumber(e) {
      this.errStatus = false;
      if (!this.validAgeFromRange(e)) {
        this.agerangefromeerr = "Please enter minimum 1 digit";
        this.errStatus = true;
      }
      if (this.agerangefrom == 0) {
        this.agerangefromeerr = "Please enter valid age";
        this.errStatus = true;
      }
      if (this.errStatus == false) {
        this.agerangefromeerr = "";
        return true;
      }
    },
    validateAgeRangeToNumber(e) {
      this.errStatus = false;
      if (!this.validAgeToRange(e)) {
        this.agerangetoeerr = "Please enter minimum 1 digit";
        this.errStatus = true;
      }
      if (parseInt(this.agerangeto) <= parseInt(this.agerangefrom)) {
        this.agerangetoeerr = "Please enter valid age range";
        this.errStatus = true;
      }
      if (this.agerangeto == 0) {
        this.agerangetoeerr = "Please enter valid age";
        this.errStatus = true;
      }
      if (this.errStatus == false) {
        this.agerangetoeerr = "";
        return true;
      }
    },
    validAgeToRange: function (e) {
      var re = /([0-9]){1,2}$/;
      return re.test(e);
    },
    activateReadMore(e) {
      this.activate_read_key_arr.push(e);
    },

    deactivateReadMore(e) {
      var index = this.activate_read_key_arr.indexOf(e);
      if (index !== -1) {
        this.activate_read_key_arr.splice(index, 1);
      }
    },
    getVisitorReports(e) {
      this.loading = true;
      this.activate_read_key_arr = [];
      this.ApiService.getVisitorReports(e).then((data) => {
        if (data.success == true) {
          this.guestList = data.data;
          this.totalRecords = data.count;
          this.loading = false;
        } else {
          this.guestList = null;
          this.totalRecords = 0;
          this.loading = false;
        }
        this.searchnameloader = false;
      });
    },
    getVisitingReason(e) {
      this.ApiService.getVisitingReason(e).then((data) => {
        if (data.status == 200) {
          this.reasonList = data.data;
        } else {
          this.reasonList = null;
        }
      });
    },
    mobileSearchFormat($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        $event.preventDefault();
      }

      var mobileInput = document.getElementById("searchmobInput");
      if (
        (mobileInput.value == "" || mobileInput.length > 1) &&
        (keyCode == 48 ||
          keyCode == 49 ||
          keyCode == 50 ||
          keyCode == 51 ||
          keyCode == 52)
      ) {
        $event.preventDefault();
      }
    },
    // Search Voter Header Input function start here
    // searchMobGuest(
    //     searchmobile
    // ) {
    //     this.hidesearchmob = true;
    //     this.searchmobloader = true;
    //     this.aa13 = searchmobile;
    //     this.getHostVisitorHistoryList({
    //         page_no: this.page_no,
    //         aa13: this.aa13,
    //     });
    //     this.searchmobbtnactive = true;
    // },
    // searchMobGuestStop() {
    //     this.searchmobbtnactive = false;
    //     this.searchmobile = "";
    //     this.getHostVisitorHistoryList({
    //         page_no: this.page_no,
    //     });
    //     this.searchmobloader = false;
    // },
    // Search Voter Header Input function end here

    // getVisitorHistroyByVisitorId() {
    //   this.showloaderguesthistorymodal = true;
    //   this.ApiService.getVisitorHistroyByVisitorId({ aa1: this.rowId }).then(
    //     (data) => {
    //       if (data.success == true) {
    //         this.guestVisitingHistoryTabDetails = data.data;
    //         this.showloaderguesthistorymodal = false;
    //       } else {
    //         this.guestVisitingHistoryTabDetails = null;
    //         this.showloaderguesthistorymodal = false;
    //       }
    //     }
    //   );
    // },
    // guestdetailModalOpen(e) {
    //   this.rowId = e.aa1;
    //   this.viewguestdetailstatus = true;
    //   this.getVisitorHistroyByVisitorId();
    //   this.aadharcardnumber = "";
    // },
    // resetGuestFilter() {
    //     this.aa30 = "";
    //     this.aa49 = null;
    //     this.aa12 = null;
    //     this.agerange = "";
    //     this.agerangefrom = '';
    //     this.agerangeto = '';
    //     this.aa18 = null;
    //     this.pincodevalue = "";
    //     this.aa24 = null;
    //     this.aadharcardnumber = "";
    //     this.aa41 = '';
    //     this.agerangeeerr = '';
    //     this.agerangefromeerr = '';
    //     this.agerangetoeerr = '';
    //     this.pincodeerr = '';
    //     this.aadharerr = '';
    //     this.getHostVisitorHistoryList({ page_no: this.page_no, });
    //     this.guestfiltermodalsflag = false;
    // },
    // agerangechange($event) {
    //     this.agerange = '';
    //     this.agerangeeerr = '';
    //     let keyCode = $event.keyCode ? $event.keyCode : $event.which;
    //     if (keyCode < 48 || keyCode > 57) {
    //         $event.preventDefault();
    //     }
    // },
    // validAadharCard: function (e) {
    //     var re = /([0-9]){12}$/;
    //     return re.test(e);
    // },
    // validatePinCodeNumber(e) {
    //     this.errStatus = false;
    //     if (!this.validPinCode(e)) {
    //         this.pincodeerr = "Please enter valid pincode";
    //         this.errStatus = true;
    //     }
    //     if (this.errStatus == false) {
    //         this.pincodeerr = "";
    //         return true;
    //     }
    // },
    // validPinCode: function (e) {
    //     var re = /^((?!(0))[0-9]{6,6})$/;
    //     return re.test(e);
    // },
    // validateAgeRangeNumber(e) {
    //     this.errStatus = false;
    //     if (!this.validAgeRange(e)) {
    //         this.agerangeeerr = "Please enter minimum 1 digit";
    //         this.errStatus = true;
    //     }
    //     if (this.agerange == 0) {
    //         this.agerangeeerr = "Please enter valid age";
    //         this.errStatus = true;
    //     }
    //     if (this.errStatus == false) {
    //         this.agerangeeerr = "";
    //         return true;
    //     }
    // },
    // validAgeFromRange: function (e) {
    //     var re = /([0-9]){1,2}$/;
    //     return re.test(e);
    // },
  },
};
</script>
<style scoped>
.company-header-box-outer {
  padding: 9px 16px;
  border: 1px solid #e0e5ed;
  border-radius: 4px 4px 0px 0px;
  border-bottom: 0;
}

.company-legal-name {
  font-family: "AcuminPro-SemiBold";
  letter-spacing: 0px;
  color: #2b2e38;
  font-size: 16px;
  line-height: 20px;
  white-space: break-spaces;
}

.company-detail-box-outer {
  padding: 16px 16px 0;
  border: 1px solid #e0e5ed;
  border-radius: 0px 0px 4px 4px;
  width: 100%;
}

.branch-detail-box-outer {
  border: 1px solid #e0e5ed;
  border-radius: 0px 0px 4px 4px;
}

.branch-detail-box-outer .branch-row-box {
  padding: 16px;
  border-bottom: 1px solid #e0e5ed;
}

.branch-detail-box-outer .branch-row-box:last-child {
  border-bottom: 0;
}

.customer-label-group {
  margin-bottom: 20px;
}

.form-label-header {
  font-family: "AcuminPro-SemiBold";
  font-size: 16px;
  letter-spacing: 0.32px;
  color: #2b2e38;
  line-height: 16px;
}

.customer-label-group .form-label {
  font-family: "AcuminPro-Regular";
  font-size: 13px;
  letter-spacing: 0px;
  color: #4a5463;
  line-height: 13px;
  margin-bottom: 6px;
}

.customer-label-group .from-label-value {
  font-family: "AcuminPro-SemiBold";
  font-size: 14px;
  letter-spacing: 0.28px;
  color: #2b2e38;
  line-height: 20px;
  word-break: break-word;
}

.company-section-outer {
  display: block;
  overflow-y: auto;
  overflow-x: hidden;
  /* height: 514px; */
}

.company-section-outer::-webkit-scrollbar {
  display: block;
}

.company-section-outer::-webkit-scrollbar {
  width: 0.3em;
  height: 0.3em;
}

.company-section-outer::-webkit-scrollbar-thumb {
  background-color: rgb(113 115 119 / 49%);
  border-radius: 3px;
}

.custom-outline-whatsapp-btn {
  background: #e4f8f0 0% 0% no-repeat padding-box;
  border: 1px solid #bbd9cc;
  border-radius: 4px;
  padding: 5px 6px;
  line-height: 10px;
}

.custom-outline-whatsapp-btn .whatsapp-color {
  color: #2ca270;
  font-size: 18px;
}

.custom-outline-call-btn {
  background: #e4f8f0 0% 0% no-repeat padding-box;
  border: 1px solid #bbd9cc;
  border-radius: 4px;
  padding: 7.5px;
  line-height: 10px;
}

.custom-outline-call-btn .call-btn-color {
  color: #2ca270;
  font-size: 13px;
}

.custom-outline-ellipsis-btn {
  padding: 0;
  border: 0;
}

.text-star-blank {
  color: #c1c5c9;
}

.voter-report-section {
  background: #ffffff 0% 0% no-repeat padding-box;
  padding: 12px;
  border: 1px solid #e0e5ed;
  border-radius: 4px 4px 0px 0px;
  border-top: 0;
}

.feedback-chart-outer {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e0e5ed;
  border-radius: 4px;
}

.feedback-chart-outer .chart-label {
  font-family: "AcuminPro-SemiBold";
  font-size: 16px;
  letter-spacing: 0.32px;
  color: #4a5463;
  text-align: left;
  line-height: 16px;
  padding: 16px;
}

.modal-bulk-next-btn.btn {
  background: #5266e5 0% 0% no-repeat padding-box;
  border-color: #5266e5;
  border-radius: 4px;
  letter-spacing: 0.26px;
  color: #ffffff;
  font-size: 13px;
  line-height: 20px;
  padding: 6px 35px;
  font-family: "AcuminPro-Regular";
}

.modal-bulk-next-btn:focus {
  box-shadow: none;
}

.user-profile-table-col {
  width: 10%;
}

.user-name-table-col {
  width: 30%;
}
.voter-exit-box-outer {
  padding: 16px 16px 0;
  background: #e4f8f0 0% 0% no-repeat padding-box;
  border: 1px solid #bbd9cc;
}

.customer-label-group {
  margin-bottom: 16px;
}

.customer-label-group .from-label-value {
  font-family: "AcuminPro-SemiBold";
  font-size: 14px;
  letter-spacing: 0.28px;
  color: #2b2e38;
  line-height: 20px;
}

.customer-label-group .form-label {
  font-family: "AcuminPro-Regular";
  font-size: 13px;
  letter-spacing: 0px;
  color: #4a5463;
  line-height: 13px;
  margin-bottom: 6px;
}
.showmorebody {
  color: #3f51b5 !important;
  cursor: pointer;
}

.showlessbody {
  color: #3f51b5 !important;
  cursor: pointer;
}
.company-detail-box-outer .history-details {
  background: #e4f8f0 0% 0% no-repeat padding-box;
}
</style>